<!--begin::Subheader-->
<div class="subheader py-2 py-lg-4" [ngClass]="subheaderClasses" id="kt_subheader">
  <div
    [ngClass]="subheaderContainerClasses"
    class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
  >
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline mr-5">
        <ng-container *ngIf="title">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-2 mr-5">
            {{ title }}
          </h5>
        </ng-container>

        <!--end::Page Title-->

        <!-- Commented out breadcrumbs, not needed currently -->
        <!--begin::Breadcrumb-->
        <!-- <ng-container *ngIf="breadcrumbs.length > 0">
          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
            <li class="breadcrumb-item">
              <a href="#"><i class="flaticon2-shelter text-muted icon-1x"></i></a>
            </li>
            <ng-container *ngFor="let item of breadcrumbs">
              <li class="breadcrumb-item">
                <a [routerLink]="item.page" [queryParams]="item.queryParams" class="text-muted">
                  {{ item.title }}
                </a>
              </li>
            </ng-container>
          </ul>
        </ng-container> -->
        <!--end::Breadcrumb-->

        <!--begin::Subtitle-->
        <ng-container *ngIf="subtitle">
          <span class="text-muted">{{ subtitle }}</span>
        </ng-container>
        <!--end::Subtitle-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->

    <div class="d-flex align-items-center">
      <ng-container *ngIf="displayDaterangepicker">
        <!--begin::Daterange-->
        <span
          class="btn btn-light btn-sm font-weight-bold mr-3"
          id="kt_dashboard_daterangepicker"
          data-toggle="tooltip"
          title="Select dashboard daterange"
          data-placement="left"
        >
          <span class="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
          <span class="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">{{ today }}</span>
        </span>
        <!--end::Daterange-->
      </ng-container>
    </div>
  </div>
</div>
<!--end::Subheader-->
