import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GlobalUtilService } from './shared-singleton/global-util.service';
import { UserDetails } from '../../views/pages/settings/models/users.model';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { Page } from 'src/app/models/page.model';
import { catchError, share, switchMap, tap } from 'rxjs/operators';
/**
 * Injectable
 */
@Injectable()
export class AssetDataService {
  /**
   * Stores the currently selected filters on assets list page for assets list
   */
  readonly storeParams = new BehaviorSubject<{ [key: string]: unknown }>(null);

  /**
   * @description observable which provides the assets results with selected params
   */
  readonly activeAssetsPage$: Observable<Page<any>> = this.storeParams.pipe(
    switchMap((params) =>
      this.getAssets(params).pipe(
        catchError((err) => {
          this.isLoadingAssets.next(false);
          return this.handleError(err, 'Unable to get assets');
        }),
        tap(() => this.isLoadingAssets.next(false))
      )
    ),
    share()
  );

  isLoadingAssets = new BehaviorSubject(false);

  constructor(private http: HttpClient, private globalUtilService: GlobalUtilService) {}

  // sends a post request to mock backend to create a new asset
  createNewAsset(asset: any) {
    return this.http.post(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/assets`,
      asset,
      {
        observe: 'response',
      }
    );
  }

  // sends a patch request to mock backend to update asset
  updateAsset(asset: any) {
    return this.http.patch(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/assets/${
        asset.id
      }`,
      asset,
      {
        observe: 'response',
      }
    );
  }

  /**
   * @description get the assets list
   * @param params
   * @returns
   */
  getAssets(params): Observable<Page<any>> {
    this.isLoadingAssets.next(true);
    return this.http.get<Page<any>>(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/assets`,
      {
        params,
      }
    );
  }

  /**
   * @description delete the asset
   * @param asset
   * @returns
   */
  deleteAsset(asset) {
    return this.http.delete(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/assets/${asset.id}`
    );
  }

  /**
   * @description request to change the state of asset to inactive
   * @param asset
   * @returns
   */
  requestStateToInactive(asset) {
    return this.http.get(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/assets/${
        asset.id
      }/request_state_change`
    );
  }

  /**
   * @description request to assign asset owner
   * @param user
   * @returns
   */
  assignAssetOwner(user: UserDetails) {
    return this.http.get<any>(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/users/${
        user.id
      }/set_asset_owner`
    );
  }

  /**
   * @description request to create bulk assets through file
   * @param data
   * @returns
   */
  uploadFile(data) {
    const header = new HttpHeaders();
    header.set('Content-Type', 'multipart/form-data');
    return this.http.post<any>(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/assets_upload`,
      data
    );
  }

  /**
   *
   * @returns the value of the filter params
   */
  getStoredParams() {
    return this.storeParams.getValue();
  }

  /**
   *  Store params for later use
   *
   * @param params params to be stored
   */
  storeSearchParams(params: { [key: string]: unknown }) {
    this.storeParams.next(params);
  }

  /**
   * @description request to cancel the alert
   * @param id
   */
  cancelAlert(id) {
    return this.http.get(
      `${environment.shepherdApi}/${
        JSON.parse(localStorage.getItem('userDetails')).organisation.id
      }/assets/${id}/cancel_alert`
    );
  }

  handleError(err, msg): Observable<never> {
    this.globalUtilService.openNotification(`${msg}: ${err.status ? err.status : 400}`, 'Warning');
    console.error(err);
    return EMPTY;
  }
}
