import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, share, switchMap, tap } from 'rxjs/operators';
import { Page } from 'src/app/models/page.model';
import { GlobalUtilService } from 'src/app/shared/services/shared-singleton/global-util.service';
import { environment } from 'src/environments/environment';
/**
 * Injectable
 */
@Injectable()
export class TagsService {
  /**
   * tag params
   */
  readonly tagParams = new BehaviorSubject<{ [key: string]: unknown }>(null);
  /**
   * tag pages results obseravble
   */
  readonly activeTagsPage$: Observable<Page<any>> = this.tagParams.pipe(
    switchMap((params) =>
      this.getAllTags(params).pipe(catchError((err) => this.handleError(err, 'Unable to get tags')))
    ),
    tap(() => this.isLoading.next(false)),
    share()
  );
  isLoading = new BehaviorSubject(false);

  constructor(private http: HttpClient, private globalUtilService: GlobalUtilService) {}

  /**
   *
   * @returns the value of the filter params
   */
  getStoredParams() {
    return this.tagParams.getValue();
  }

  /**
   *  Store params for later use
   *
   * @param params params to be stored
   */
  storeSearchParams(params: { [key: string]: unknown }) {
    this.tagParams.next(params);
  }

  /**
   * returns tag results
   *
   * @param params
   */
  getAllTags(params): Observable<Page<any>> {
    this.isLoading.next(true);
    return this.http.get<Page<any>>(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/tags`,
      { params }
    );
  }

  /**
   * creates a tag
   *
   * @param tag tag data
   */
  createTag(tag) {
    return this.http.post(
      `${environment.shepherdApi}/${JSON.parse(localStorage.getItem('userDetails')).organisation.id}/tags`,
      tag
    );
  }

  /**
   * handles error from api requests
   *
   * @param err
   * @param msg
   */
  handleError(err, msg): Observable<never> {
    this.isLoading.next(false);
    this.globalUtilService.openNotification(`${msg}: ${err.status ? err.status : 400}`, 'Warning');
    console.error(err);
    return EMPTY;
  }
}
