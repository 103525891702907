export const environment = {
  claranetonlineUrlMap: {
    UK: 'https://online.uk.clara.net',
    PT: 'https://online.claranet.pt',
    DE: 'https://online.claranet.de',
    NL: 'https://online.claranet.nl',
    ES: 'https://online.claranet.es',
  },
  production: true,
  auth0ClientId: 'jjIB1am7fxx4mH7L3jzxTfFqODy9qvdO',
  auth0Domain: 'claranet.eu.auth0.com',
  auth0Scope: 'read:profile read:vulnerabilities',
  auth0Audience: 'https://uk-cst-portal-api',
  clientCode: 'iam.customer',
  redirectUri: 'https://cyberportal.claranet.com/dashboard',
  threatMineApi: 'https://api.threatmine.247secure.net',
  shepherdApi: 'https://api.cyberportal.claranet.com/customer',
  dirtyfixApi: 'https://api.cyberportal.claranet.com/vulnerabilities',
  mockBackend: '',
  jiraApi: '',
  auth0ErrorPath: 'https://customer.247secure.net/error/error-2',
  notificationApi: 'https://api.cyberportal.claranet.com',
  cyberportalBaseUrl: 'https://cyberportal.claranet.com',
};
