import { TutorialStepConfig } from '../types/tutorial-step-config';
import { clickElement, createPermissionsWarning } from './helpers';

export const assetStepConfigs: TutorialStepConfig[] = [
  {
    title: 'Assets Page',
    text:
      "The assets page shows a list of your assets.<br><br>On this page you can: <ul><li>Search for an asset</li><li>Create a new asset</li><li>Edit or delete an asset</li><li>Request to change an asset's state to inactive</li><li>View an asset's notification</li><li>Assign an asset owner</li></ul>",
  },
  {
    title: 'Asset List',
    text:
      'Your assets are listed here.<br><br>You can see more information about an asset by clicking its name in the Name column.<br><br>If an asset has any notifications, the warning sign will be shown in red. Clicking the red alert icon will show the alert popup. Here you can view comments relating to the alert and decide to keep or cancel the alert.',
    attachTo: {
      element: '#tutorial-assets-table',
      on: 'bottom',
    },
  },
  {
    title: 'Create an Asset',
    text:
      createPermissionsWarning(['Assets', 'create']) +
      'The process of creating an asset is begun by clicking the Add an asset button',
    attachTo: {
      element: '#addNew',
      on: 'bottom',
    },
    extraAction: () => {
      clickElement('addNew');
    },
  },
  {
    title: 'Create An Asset Popup',
    text:
      createPermissionsWarning(['Assets', 'create']) +
      'There are two ways of creating an asset. Filling the input form on the left or downloading, editing, and uploading a .csv template file.',
    attachTo: {
      element: '.modal-content',
      on: 'top-start',
    },
    waitForDomUpdate: true,
  },
  {
    title: 'On Creating an Asset',
    text:
      'Once created, the asset will have the status "Pending". It will then be reviewed and approved by our security team.',
    extraAction: () => {
      const closeModalCross = document.getElementsByClassName('ki-close')[0] as HTMLElement;
      closeModalCross.click();
    },
  },
  // {
  //   title: 'Assign an Asset Owner',
  //   text:
  //     createPermissionsWarning(['Assets', 'assign']) +
  //     'An asset owner has admin permisson levels in relation to assets. An asset owner can be assigned by clicking the Assign Asset Owner button. After doing so, you will be given a list of users that you can select from.',
  //   attachTo: {
  //     element: '#tutorial-assets-assign-user',
  //     on: 'bottom',
  //   },
  // },
  {
    title: 'Edit, Delete, or Request Inactive Status',
    text:
      createPermissionsWarning(['Assets', 'edit'], ['Assets', 'delete']) +
      "Assets can be edited or deleted. A request can also be sent to our security team to set an asset's status to inactive.",
  },
  {
    title: 'Select an Asset to Modify',
    text:
      createPermissionsWarning(['Assets', 'edit'], ['Assets', 'delete']) +
      'You can choose which asset you wish to modify by ticking its checkbox in the asset table.',
    attachTo: {
      element: '#tutorial-assets-checkbox',
      on: 'right',
    },
    extraAction: () => {
      clickElement('tutorial-assets-checkbox');
    },
  },
  {
    title: 'The Actions Button',
    text:
      createPermissionsWarning(['Assets', 'edit'], ['Assets', 'delete']) +
      'When an asset is selected, the Actions button will be available to click on.',
    attachTo: {
      element: '#dropdownBasic1',
      on: 'left',
    },
    extraAction: () => {
      clickElement('dropdownBasic1');
    },
  },
  {
    title: 'Actions Menu',
    text:
      createPermissionsWarning(['Assets', 'edit'], ['Assets', 'delete']) +
      "A list of actions will be shown after clicking the button. Here you can edit, delete, and request to change the asset's status to inactive.",
    attachTo: {
      element: '#tutorial-assets-action-menu',
      on: 'left',
    },
    waitForDomUpdate: true,
  },
];
