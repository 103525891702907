import { TutorialStepConfig } from '../types/tutorial-step-config';
import { clickElement, createPermissionsWarning } from './helpers';

export const vulnerabilityStepsConfig: TutorialStepConfig[] = [
  {
    title: 'Vulnerabilities Page',
    text:
      'The vulnerabilities page shows a list of your vulnerabilities.<br><br>On this page you can: <ul><li>Search for a vulnerability</li><li>Filter the type of vulnerabilities shown</li><li>Select vulnerabilities to generate a report for</li><li>Export all vulnerabilities in a .csv file</li></ul>',
  },
  {
    title: 'Vulnerabilities Table',
    text:
      'A list of your vulnerabilities are shown in this table. Here you can see the title, asset, id, status, CVSS score of a vulnerability. Clicking on a vulnerability will take you to the detail page providing you with more information.<br><br> By default, vulnerabilities are filtered to only show unfixed vulnerabilities. This can be changed by opening the filter options.',
    attachTo: {
      element: '#tutorial-vulnerability-table',
      on: 'bottom',
    },
  },
  {
    title: 'Opening Filter Options',
    text: 'This button above the table will open the filter options. Click continue to see the filter options.',
    attachTo: {
      element: '#tutorial-vulnerability-open-filter-options',
      on: 'left',
    },
    extraAction: () => {
      clickElement('tutorial-vulnerability-open-filter-options');
    },
  },
  {
    title: 'Filter Options',
    text:
      'There are many filters that can be applied to change the vulnerability list.<br><br>The list can be filtered by:<ul><li>The level of impact or probablility</li><li>Asset tags</li><li>Status</li><li>Date range</li><li>A custom input to search for or exclude specifc values</li></ul>',
    waitForDomUpdate: true,
    attachTo: {
      element: '#tutorial-vulnerability-filters',
      on: 'bottom',
    },
  },
  {
    title: 'Additional Custom Inputs',
    text:
      'Up to five further custom inputs can be added. The previous inputs must be valid before being able to add another.',
    attachTo: {
      element: '#tutorial-vulnerability-additional-input',
      on: 'left',
    },
  },
  {
    title: 'Apply New Filter Options',
    text: 'To apply your new options, click the apply button.',
    attachTo: {
      element: '#apply',
      on: 'left',
    },
  },
  {
    title: 'Saving A Filter',
    text:
      createPermissionsWarning(['Vulnerability', 'create']) +
      'A filter can be saved so you can conveniently apply these settings in the future.',
    attachTo: {
      element: '#tutorial-vulnerability-saving-filter',
      on: 'left',
    },
  },
  {
    title: 'Saved Filters',
    text: 'Saved filters can be seen by clicking on this button.',
    attachTo: {
      element: '#tutorial-vulnerability-open-saved-filters',
      on: 'left',
    },
    extraAction: () => {
      clickElement('tutorial-vulnerability-open-saved-filters');
    },
  },
  {
    title: 'Apply, Edit, or Delete Filters',
    text:
      createPermissionsWarning(['Vulnerability', 'edit'], ['Vulnerability', 'delete']) +
      'Your saved filters can be applied, edited, or deleted.',
    waitForDomUpdate: true,
    attachTo: {
      element: '.popover',
      on: 'left',
    },
    extraAction: () => {
      clickElement('body', 'tag');
    },
  },
  {
    title: 'Select Vulnerabilities for Report Generation',
    text:
      createPermissionsWarning(['Reporting', 'create']) +
      'Vulnerabilities are selected for a report by clicking the checkbox next to their title. Multiple vulnerabilities can be selected.',
    attachTo: {
      element: '#tutorial-vulnerability-checkbox',
      on: 'right',
    },
    extraAction: () => {
      clickElement('tutorial-vulnerability-checkbox');
    },
  },
  {
    title: 'Begin Report Generation',
    text:
      createPermissionsWarning(['Reporting', 'create']) +
      'When vulnerabilities are selected, you can begin the report generation building process by clicking this button',
    attachTo: {
      element: '#tutorial-vulnerability-generate-report',
      on: 'right',
    },
    extraAction: () => {
      clickElement('tutorial-vulnerability-generate-report');
    },
  },
  {
    title: 'Building a Report',
    text:
      createPermissionsWarning(['Reporting', 'create']) +
      'Name your report and click the Create report button to begin building your report.<br><br>Please be patient while the report is being built. This can take up to a few minutes to complete. Your download will begin as soon as the build is complete.<br><br>You can continue to navigate the Portal while you wait but only one report can be built at a time.',
    waitForDomUpdate: true,
    attachTo: {
      element: '.popover',
      on: 'left',
    },
  },
  {
    title: 'Export Vulnerabilities in a CSV File',
    text:
      createPermissionsWarning(['Reporting', 'create']) +
      'Export all vulnerabilites that meet the current filter options by clicking this button.',
    attachTo: {
      element: '#tutorial-vulnerability-export-csv',
      on: 'right',
    },
    extraAction: () => {
      clickElement('tutorial-vulnerability-export-csv');
    },
  },
  {
    title: 'Export Vulnerabilities in a CSV File',
    text:
      createPermissionsWarning(['Reporting', 'create']) +
      'Name the file and then click the export button to begin downloading all vulnerabilities to export in a .csv file.',
    waitForDomUpdate: true,
    attachTo: {
      element: '.popover',
      on: 'left',
    },
  },
];
