import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Modal2Component } from './dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatSortModule } from '@angular/material/sort';
import { SeverityPipe } from 'src/app/core/_helpers/pipes/severity.pipe';
import { PaginatorComponent } from './paginator/paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { StatusPipe } from '../core/_helpers/pipes/status.pipe';
import { TableSortArrowsComponent } from './table-sort-arrows/table-sort-arrows.component';
import { CvssColourCircleComponent } from './cvss-colour-circle/cvss-colour-circle.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AddInventoryItemComponent } from './add-inventory-item/add-inventory-item.component';
import { CreateTagComponent } from './create-tag/create-tag.component';
import { TagsService } from './services/tags.service';
import { AssetDataService } from './services/asset-data.service';
import { TagInputModule } from 'ngx-chips';

@NgModule({
  declarations: [
    PaginatorComponent,
    Modal2Component,
    TableSortArrowsComponent,
    CvssColourCircleComponent,
    AddInventoryItemComponent,
    CreateTagComponent,
    // pipes
    StatusPipe,
    SeverityPipe,
  ],
  entryComponents: [Modal2Component],
  imports: [
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatListModule,
    MatSortModule,
    MatSnackBarModule,
    CdkAccordionModule,
    TagInputModule,
  ],
  providers: [TagsService, AssetDataService],
  exports: [
    ReactiveFormsModule,
    PaginatorComponent,
    MatButtonToggleModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatSortModule,
    MatRadioModule,
    MatPaginatorModule,
    MatTableModule,
    MatSnackBarModule,
    CdkAccordionModule,
    TableSortArrowsComponent,
    CvssColourCircleComponent,
    AddInventoryItemComponent,
    CreateTagComponent,
    // pipes
    StatusPipe,
    SeverityPipe,
  ],
})
export class SharedModule {}
