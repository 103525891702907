import { TutorialStepConfig } from '../types/tutorial-step-config';
import { createPermissionsWarning } from './helpers';

export const dashboardStepConfigs: TutorialStepConfig[] = [
  {
    title: 'Your Dashboard',
    text: 'This is your dashboard. Here you can see statistics relating to your findings (vulnerabilities).',
  },
  {
    title: 'Impact Chart',
    text:
      'This chart shows you the proportion of vulnerabilites by the level of impact they have.<br><br>Vulnerabilities with the impact level of "Info" are not shown here.',
    attachTo: {
      element: '#tutorial-dashboard-impact',
      on: 'right',
    },
  },
  {
    title: 'Overview Chart',
    text: 'This chart shows you the amount of vulnerabilities discovered each month over the past year.',
    attachTo: {
      element: '#tutorial-dashboard-overview',
      on: 'bottom',
    },
  },
  {
    title: 'Unfixed Chart',
    text:
      'This chart shows you the proportion of unfixed vulnerabilities by the time they were discovered.<br><br>A percentage of how many vulnerabilities are unfixed is shown at the bottom.',
    attachTo: {
      element: '#tutorial-dashboard-unfixed',
      on: 'right',
    },
  },
  {
    title: 'Occurances Table',
    text: 'This table shows you a list of vulnerabilities that occured the most frequent this month.',
    attachTo: {
      element: '#tutorial-dashboard-occurrences',
      on: 'top-start',
    },
  },
  {
    title: 'Assets Table',
    text: 'This table shows you a list of your assets which have the most vulnerabilities.',
    attachTo: {
      element: '#tutorial-dashboard-assets',
      on: 'top-start',
    },
  },
  {
    title: 'Create an Asset',
    text:
      createPermissionsWarning(['Assets', 'create']) +
      'The process of creating an asset is begun by clicking the Add an asset button. This will open the Create an asset popup. The Assets page tutorial will provide greater detail on this.',
    attachTo: {
      element: '#tutorial-dashboard-add-asset-button',
      on: 'right',
    },
  },
];
